import React from "react";

export const About = () => {
  return (
    <div className="about">
      <h1>About This Site</h1>
      <p>This site's purpose is as a portfolio for James Funk's small projects and other software he develops.</p>
      <p>This site uses React and BootStrap for the frontend. It is written mainly in Javascript. It is hosted on Github using github pages.</p>
      <p>This site should NEVER ask you for personal information. This site will ask for your location information for the weather app, which can be declined.</p>
    </div>
  );
};